import 'component/appRoot/header/header.scss';

import { API } from 'api/api';
import burger from 'assets/images/common/burger.svg';
import closeCross from 'assets/images/common/closeCross.svg';
import mobileLogout from 'assets/images/common/mobileLogout.svg';
import lock from 'assets/images/lock.svg';
import check from 'assets/images/menuItems/check.svg';
import logout from 'assets/images/share-arrow.svg';
import user from 'assets/images/user.svg';
import { Logo } from 'component/appRoot/header/logo/logo';
import { NavigationMenu } from 'component/appRoot/NavigationMenu';
import { useBreadcrumbContext } from 'component/context/BreadcrumbContext';
import {
  dialogContext,
  notificationContext,
  userContext,
} from 'component/context/context';
import { useSidebarContext } from 'component/context/SidebarContext';
import { Breadcrumbs } from 'component/customComponents/breadcrumb/breadcrumbs';
import { useBreakpoint } from 'hooks';
import { ChangePasswordDialog } from 'pages/main/dialog/changePasswordDialog';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { LayoutTextSidebar } from 'react-bootstrap-icons';
import Navbar from 'react-bootstrap/Navbar';
import { useHistory } from 'react-router';

type HeaderProps = {
  onLogout: () => void;
  uncheckedStudentsTests?: number;
  uncheckedTests?: number;
};

function Header({
  onLogout,
  uncheckedStudentsTests,
  uncheckedTests,
}: HeaderProps) {
  const { firstName, middleName, lastName, type } = useContext(userContext);
  const addNotification = useContext(notificationContext);
  const { openSidebar, isSidebarOpen, closeSidebar } = useSidebarContext();
  const { breadcrumbsItems } = useBreadcrumbContext();
  const breakpoint = useBreakpoint();

  const showOpenSidebarButton = !(
    breakpoint === 'xl' ||
    breakpoint === 'exl' ||
    breakpoint === 'xxl' ||
    breakpoint === 'xs'
  );
  const isMobileView = breakpoint === 'xs';
  const shouldShowBreadcrumbs =
    breakpoint === 'md' ||
    breakpoint === 'lg' ||
    breakpoint === 'xl' ||
    breakpoint === 'exl' ||
    breakpoint === 'xxl';

  const history = useHistory();

  const userName = `${lastName} ${firstName} ${
    middleName ? `${middleName} ` : ''
  }`;

  const showDialog = useContext(dialogContext);

  const onPasswordChange = async () => {
    const dialogResult = await showDialog(<ChangePasswordDialog />);
    if (!dialogResult) {
      return;
    }

    const newPassword = dialogResult as string;

    if (newPassword.length < 6) {
      return;
    }

    try {
      await API.User.changePassword(newPassword);
      addNotification('Пароль успешно изменен', 'success');
    } catch (e: any) {
      addNotification(e.message, 'error');
    }
  };

  return (
    <>
      <Navbar
        sticky="top"
        role="navigation"
        // @ts-ignore
        variant="base"
        className="containerCustom"
      >
        <div className="container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {showOpenSidebarButton && (
              <LayoutTextSidebar
                onClick={openSidebar}
                className="sidebar-btn"
              />
            )}
            <Navbar.Brand
              className="navbar-brand"
              onClick={() => history.push('/main')}
            >
              <div style={{ width: !isMobileView ? '245px' : '185px' }}>
                <Logo />
              </div>
            </Navbar.Brand>
          </div>
          <Navbar.Text>
            {!isMobileView ? (
              <div className="user">
                <img className="avatar" alt="img" src={user} />
                <div className="usr-data mx-2">
                  {!isMobileView && <div className="username">{userName}</div>}
                </div>
                {type?.role === 'student' && (
                  <img
                    className="mx-2"
                    alt="img"
                    src={lock}
                    onClick={onPasswordChange}
                  />
                )}
                <img
                  className="logout"
                  alt="img"
                  src={logout}
                  onClick={onLogout}
                />
              </div>
            ) : (
              <div className="user">
                <div className="mobileMenuControls">
                  {type?.role === 'student' && (
                    <div
                      className="checkMenu"
                      onClick={() => {
                        history.push('/studentPersonalAccount');
                      }}
                    >
                      <img alt={'img'} src={check} />
                      {uncheckedStudentsTests && (
                        <Badge
                          className="unchecked-badge"
                          pill
                          variant="danger"
                        >
                          {uncheckedStudentsTests}
                        </Badge>
                      )}
                      {type?.role === 'student' && (
                        <img
                          alt="img"
                          className="mx-2"
                          src={lock}
                          onClick={onPasswordChange}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className="burgerControl"
                    onClick={isSidebarOpen ? closeSidebar : openSidebar}
                  >
                    <img
                      alt={'img'}
                      src={isSidebarOpen ? closeCross : burger}
                    />
                  </div>
                </div>
              </div>
            )}
          </Navbar.Text>
        </div>
      </Navbar>
      {isSidebarOpen && isMobileView && (
        <div
          className="mobileOverlay"
          onClick={(e) => {
            e.stopPropagation();
            closeSidebar();
          }}
        >
          <div className="mobileNavigationMenuContainer">
            <NavigationMenu
              onItemClick={closeSidebar}
              uncheckedStudentsTests={uncheckedStudentsTests}
              uncheckedTests={uncheckedTests}
            />
            <div className="mobileLogout" onClick={onLogout}>
              <img alt="img" src={mobileLogout} className={'logoutIcon'} />
              <div className="text">Смена пользователя</div>
            </div>
          </div>
        </div>
      )}

      {breadcrumbsItems && shouldShowBreadcrumbs && (
        <Breadcrumbs
          className="breadcrumbs sidebar-margin"
          items={breadcrumbsItems}
        />
      )}
    </>
  );
}

Header.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export { Header };
