// @ts-nocheck
import {
  BackgroundSetItem,
  CourseDetailItem,
  ExistedSocialLinkItem,
  ImageListItem,
  StructureType,
  TAllLessons,
  TAllStudentLessonsEntity,
  TAudioUrlEntity,
  TAvailableTestsEntity,
  TCategory,
  TCheckedTestEntity,
  TCoursesMetadataEntity,
  TDescriptionEntity,
  TExtendedTestsStats,
  TGetAllExtendedTestsEntity,
  TGetAvailableQuestionsEntity,
  TGetLessonByIdEntity,
  TGetLinkElementsEntity,
  TGetMediaElementsEntity,
  TGroupsEntity,
  TJournalEntity,
  TLessonPublishedDateEntity,
  TMetadataEntity,
  TStudentWork,
  TTeachersEntity,
  TTemplate,
  TTotalUncheckedTestsEntity,
  TUncheckedStudentsTestsEntity,
  TUncheckedTestEntity,
  TUserInfoEntity,
  TWorksGetAll,
  TeacherProfile,
  TeacherUpdateParams,
  TeacherUpdateResult,
  VkAuthResponse,
} from 'api/entities';
import axiosLib from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SELF_URL = process.env.REACT_APP_SELF_URL;

const axios = axiosLib.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

axios.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (config.baseURL === BASE_URL && !config.headers.Authorization) {
      const token = localStorage.getItem('JWT');
      if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

export const API = {
  metadata: () => axios.get<TMetadataEntity>('/metadata'),
  courseContent: (course_id) =>
    axios.get<TMetadataEntity>('/content', { params: { course_id } }),
  logIn: (login: string, password: string) =>
    axios.post<string>('/validation/log_in', null, {
      params: { login, password },
    }),
  auth: (code: string) =>
    axios.post<VkAuthResponse | string>('/validation/auth', null, {
      params: { code },
    }),
  courses_metadata: () =>
    axios.get<TCoursesMetadataEntity>('/courses_metadata'),
  get_lessons: (studentId) =>
    axios.get<TAllStudentLessonsEntity[]>(`/get_lessons/${studentId}`),
  User: {
    findUsers: (name?: string) =>
      axios.get('/user/find_users', { params: { name } }),
    user: (id) => axios.get<TUserInfoEntity>(`/user/user/${id}`),
    teachers: () => axios.get<TTeachersEntity[]>('/user/teachers'),
    changePassword: (password: string) =>
      axios.post(`/user/auth_update?password=${password}`),
    Info: {
      self: () => axios.get('/user/info/self'),
    },
    // Используется для получения списка учителей, которых можно прикрепить к курсу
    teachersProfiles: () =>
      axios.get<TeacherProfile[]>('/user/teachers_profiles'),

    // Обновление или создание учителя для курса
    teacherUpdate: (data: TeacherUpdateParams) =>
      axios.post<TeacherUpdateResult>(`/user/teacher/update`, {
        name: data.name,
        id: data.id,
        social_links: data.socialLinks?.map((link) => ({
          link: link.link,
          social_id: link.socialId,
        })),
      }),

    socialsList: () => axios.get<ExistedSocialLinkItem[]>('/user/socials/get'),
    // Добавление картинки для выбранного преподавателя
    addTeacherImage: (teacherId, data, extension) =>
      axios.post(`/user/teacher/${teacherId}/image/upload`, data, {
        params: { extension },
      }),
  },
  Stats: {
    Lesson: {
      get: (id) => axios.get(`/stats/lesson/${id}/get_stats`),
    },
    Test: {
      getExamTime: (testId) => axios.get(`/stats/test/${testId}/get_exam_time`),
      nullifyExam: (testId, userId) =>
        axios.post(`/stats/test/${testId}/nullify_exam`, null, {
          params: { user_id: userId },
        }),
    },
    get: (testId, userId) =>
      axios.get('/stats/get', { params: { test_id: testId, user_id: userId } }),
  },
  Structures: {
    create: (type, name, parentId) =>
      axios.post(`/structure/${type}/create`, { name, parentId }),
    rename: (type: string, id: number, name: string) =>
      axios.post(`/structure/${type}/${id}/update`, { name }),
    delete: (type: string, id: number) =>
      axios.post(`/structure/${type}/${id}/delete`),
    exists: (type, id) => axios.get(`/structure/${type}/${id}/exists`),
    move: (type: StructureType, id: number, place: number) =>
      axios.post(`/structure/${type}/${id}/move`, null, { params: { place } }),
    Description: {
      create: (type, id) =>
        axios.post(`/structure/description/${type}/${id}/create`),
      update: (
        type,
        id,
        data: {
          text?: string;
          teacherId?: string;
          teacherName?: string;
          imageId?: string;
          titleBackgroundId?: string;
          title?: string;
          courseDetails?: CourseDetailItem[];
        }
      ) => {
        // TODO Move parsing to different layer
        const requestPayload = {};
        if (data.text) {
          requestPayload.text = data.text;
        }
        if (data.teacherId) {
          requestPayload.teacher = {};
          requestPayload.teacher.id = data.teacherId;
          requestPayload.teacher.name = data.teacherName;
        }
        if (data.imageId) {
          requestPayload.image = {};
          requestPayload.image.id = data.imageId;
        }
        if (data.courseDetails) {
          requestPayload.course_details = data.courseDetails;
        }
        if (data.titleBackgroundId) {
          requestPayload.colour = {};
          requestPayload.colour.id = data.titleBackgroundId;
        }
        if (data.title) {
          requestPayload.title = data.title;
        }
        return axios.post(
          `structure/description/${type}/${id}/update`,
          requestPayload
        );
      },
      delete: (type, id) =>
        axios.post(`/structure/description/${type}/${id}/delete`),
      get: (type, id) =>
        axios.get<TDescriptionEntity>(
          `/structure/description/${type}/${id}/exists`
        ),
    },
    Courses: {
      giveAllPermissions: (id, groupId) =>
        axios.post(`/structure/course/${id}/give_all_permissions`, null, {
          params: { groupId },
        }),
      removeAllPermissions: (id, groupId) =>
        axios.post(`/structure/course/${id}/remove_all_permissions`, null, {
          params: { groupId },
        }),
      getCourseTeachers: (courseId) =>
        axios.get<TTeachersEntity[]>(
          `/structure/course/${courseId}/teachers/get`
        ),
      addAndDeleteTeachersFromCourse: (courseId: number, teacherId: number[]) =>
        axios.post(`/structure/course/${courseId}/teachers/update`, teacherId),
      copyCourse: (courseId) =>
        axios.post(`/structure/course/${courseId}/copy`),
      // Получить картинки, которые можно будет выбрать для отображения на карточке курса
      getCourseImages: () =>
        axios.get<ImageListItem[]>(`/structure/course/image/get`),
      addCourseImage: (data, extension) =>
        axios.post('/structure/course/image/upload', data, {
          params: { extension },
        }),
      getBackgroundColorsSet: () =>
        axios.get<BackgroundSetItem[]>(`/structure/course/colour/get`),
    },
    Lesson: {
      getLessonById: (id) =>
        axios.get<TGetLessonByIdEntity>(
          `/structure/lesson/getLessonByID/${id}`
        ),
      getPublishedDate: (lessonId) =>
        axios.get<TLessonPublishedDateEntity>(
          `structure/lesson/${lessonId}/published_time/exists`
        ),
      updatePublishedDate: (lessonId, date) =>
        axios.post(
          `structure/lesson/${lessonId}/published_time/update?published_at=${date}`
        ),
      updateCheckedLessonStatus: (lessonId, userId, status) =>
        axios.post(
          `/structure/lesson/${lessonId}/desplayed_lesson/update?user_id=${userId}&was_desplayed=${status}`
        ),
      getAllLessons: () =>
        axios.get<TAllLessons[]>('/structure/lesson/get_all'),
      copyLesson: (id, subject_id) =>
        axios.post(`/structure/lesson/${id}/copyto/${subject_id}`),
      getLessonImages: () =>
        axios.get<ImageListItem[]>(`/structure/lesson/image/get`),
      addLessonImage: (data, extension) =>
        axios.post('/structure/lesson/image/upload', data, {
          params: { extension },
        }),
    },
    Subject: {
      getAllSubjects: () =>
        axios.get<TAllLessons[]>(`/structure/subject/get_all `),
      copySubject: (subjectId, courseId) =>
        axios.post(`/structure/subject/${subjectId}/copyto/${courseId} `),
    },
    Categories: {
      get: () => axios.get<TCategory[]>('/structure/category/get'),
      create: (name) => axios.post('/structure/category/create', { name }),
      addCourse: (categoryId, courseId) =>
        axios.post(`/structure/category/${categoryId}/courses/${courseId}/`),
      removeCourse: (categoryId, courseId) =>
        axios.post(
          `/structure/category/${categoryId}/courses/${courseId}/remove`
        ),
    },
  },
  Media: {
    getOrder: (mediaType, lessonId) =>
      axios.get<TGetMediaElementsEntity[]>(
        `/media/${mediaType}/get_order?lesson_id=${lessonId}`
      ),
    getOrderLinks: (mediaType, lessonId) =>
      axios.get<TGetLinkElementsEntity[]>(
        `/media/${mediaType}/get_order?lesson_id=${lessonId}`
      ),
    move: (mediaType, id, place) =>
      axios.post(`/media/${mediaType}/${id}/move?place=${place}`),
    getURL: (mediaType, id) =>
      `${BASE_URL}/media/${mediaType}/${id}/get?jwt=${localStorage.getItem(
        'JWT'
      )}`,
    getVideoUrl: (mediaType, id) =>
      axios.get<string>(`/media/${mediaType}/${id}/get`),
    uploadURL: (mediaType, lessonId, extension, name?: string) =>
      `${BASE_URL}/media/${mediaType}/upload?lesson_id=${lessonId}&extension=${extension}&name=${name}`,
    uploadVideoByUrl: (lessonId, name, url) =>
      axios.post(
        `/media/link/upload?lesson_id=${lessonId}&name=${name}&url=${url}`
      ),
    delete: (mediaType, id) => axios.post(`/media/${mediaType}/${id}/delete`),
    Name: {
      get: (mediaType, id) =>
        axios.get<string>(`/media/${mediaType}/${id}/name/get`),
      set: (mediaType, id, name) =>
        axios.post(`/media/${mediaType}/${id}/name/set`, null, {
          params: { name },
        }),
    },
  },
  Questions: {
    getAvailableQuestions: (lessonId, testType) =>
      axios.get<TGetAvailableQuestionsEntity>(
        '/question/get_available_questions',
        {
          params: { lesson_id: lessonId, test_type: testType },
        }
      ),
    get: (questionId) => axios.get(`/question/${questionId}/get`),
    update: (id, data) => axios.post(`/question/${id}/update`, data),
    create: (data) => axios.post('/question/create', data),
    delete: (id) => axios.post(`/question/${id}/delete`),
    getQuestionsList: (pageNumber: number) =>
      axios.get('/question/questions', {
        params: {
          test_type: 'exercise',
          page: pageNumber,
          page_size: 50,
        },
      }),
    updateQuestionsTags: (id, data) =>
      axios.post(`/question/questions/${id}/tags`, data),
    Image: {
      uploadURL: (parentQuestion, extension) =>
        `${BASE_URL}/question/${parentQuestion}/image/upload?extension=${extension}`,
      probe: (id) => axios.get(`/question/${id}/image/exists`),
      delete: (id) => axios.post(`/question/${id}/image/delete`),
      getURL: (id) =>
        `${BASE_URL}/question/${id}/image/get?jwt=${localStorage.getItem(
          'JWT'
        )}&salt=${Math.random()}`,
    },
  },
  Tests: {
    getAvailableTests: (testType, lessonId) =>
      axios.get(
        `/test/get_available_tests_teacher?test_type=${testType}&lesson_id=${lessonId}`
      ),
    check: (id, answers) => axios.post(`/test/${id}/check`, answers),
    getAvailableTestsStudent: (testType, lessonId) =>
      axios.get('/test/get_available_tests_student', {
        params: { test_type: testType, lesson_id: lessonId },
      }),
    getMetadata: (id) => axios.get(`/test/${id}/get_metadata`),
    create: (parentLessonId, type, options) =>
      axios.post('/test/create', options, {
        params: { parent_lesson_id: parentLessonId, test_type: type },
      }),
    update: (testId, options) => axios.post(`/test/${testId}/update`, options),
    delete: (testId) => axios.post(`/test/${testId}/delete`),
    getOptions: (id) => axios.get(`/test/${id}/get_options`),
    availableExamsForGroup: (groupId) =>
      axios.get('/test/available-exams-for-group', { params: { groupId } }),
    AllowedGroups: {
      get: (id) => axios.get(`/test/${id}/allowed_groups/get`),
      set: (id, values) => axios.post(`/test/${id}/allowed_groups/set`, values),
    },
  },
  Chat: {
    get: () => axios.get('/chat/get'),
    write: (message) => axios.post('/chat/write', message),
    deleteMessage: (id) => axios.post(`/chat/delete-message?id=${id}`),
  },
  Teacher: {
    getGroupsForTest: (testId) =>
      axios.get('/teacher/get_groups_for_test', {
        params: { test_id: testId },
      }),
  },
  Journal: {
    get: (groupId, key) =>
      axios.get<TJournalEntity>('/journal/get', {
        params: { groupId, key: key || undefined },
      }),
    getAsStudent: (groupId) =>
      axios.get('/journal/get-as-student', { params: { groupId } }),
    assignTest: (scheduleItemId, testId) =>
      axios.post(`/journal/${scheduleItemId}/assign-test`, null, {
        params: { testId },
      }),
    addScheduleItem: (groupId, date, title, place) =>
      axios.post('/journal/add-schedule-item', null, {
        params: {
          groupId,
          date,
          place,
          title,
        },
      }),
    setPresence: (data) => axios.post('/journal/set-presence', data),
    Keys: {
      get: (groupId) =>
        axios.get<string>('/journal/keys', { params: { groupId } }),
      generate: (groupId) =>
        axios.post('/journal/keys/generate', null, { params: { groupId } }),
    },
    ScheduleItems: {
      create: (data) => axios.post('/journal/schedule-items/create', data),
      update: (id, data) =>
        axios.post(`/journal/schedule-items/${id}/update`, data),
      delete: (id) => axios.post(`/journal/schedule-items/${id}/delete`, null),
      scheduleItemsFromCsv: (groupId: number) =>
        `${BASE_URL}/journal/schedule-items-from-csv/${groupId}`,
    },
    Columns: {
      create: (parentId, data) =>
        axios.post(`/journal/${parentId}/add-column`, data),
      delete: (id) => axios.post(`/journal/columns/${id}/delete`, null),
      update: (id, data) => axios.post(`/journal/columns/${id}/update`, data),
      placeMark: (id, data) =>
        axios.post(`/journal/columns/${id}/place-mark`, data),
    },
  },
  Groups: {
    get: () => axios.get<{ groups: TGroupsEntity[] }>('/groups'),
  },
  ExtendedTests: {
    get: (id) => axios.get(`/extended-tests/${id}`),
    getAll: (lessonId?: string) =>
      axios.get<{ tests: TGetAllExtendedTestsEntity[] }>(
        '/extended-tests/all',
        { params: { lessonId } }
      ),
    getAllPagination: (from: number, to: number) =>
      axios.get<{ tests: TGetAllExtendedTestsEntity[] }>(
        `/extended-tests/get_all?page=${from}&per_page=${to}`
      ),
    create: (lessonId, data) =>
      axios.post('/extended-tests/create', { ...data, lessonId }),
    update: (id, data) => axios.post(`/extended-tests/${id}/update`, data),
    delete: (id) => axios.post(`/extended-tests/${id}/delete`),
    availableForGroup: (groupId) =>
      axios.get<{ availableTests: TAvailableTestsEntity[] }>(
        '/extended-tests/available-for-group',
        { params: { groupId } }
      ),
    stats: (userId) =>
      axios.get<{ stats: TExtendedTestsStats[] }>('/extended-tests/stats', {
        params: { userId },
      }),
    tests_to_check: (teacherId: number) =>
      axios.get<TUncheckedTestEntity[]>('/extended-tests/tests-to-check', {
        params: { teacherId },
      }),
    get_checked_tests: (studentId: number) =>
      axios.get<TCheckedTestEntity[]>('/extended-tests/get-checked-tests', {
        params: { studentId },
      }),
    get_unchecked_tests: (studentId: number) =>
      axios.get<TUncheckedStudentsTestsEntity[]>(
        '/extended-tests/get-unchecked-tests',
        {
          params: { studentId },
        }
      ),
    get_review_tests: (studentId: number) =>
      axios.get<TCheckedTestEntity[]>('/extended-tests/get-review-tests', {
        params: { studentId },
      }),
    get_total_unchecked_tests: (studentId: number) =>
      axios.get<TTotalUncheckedTestsEntity>(
        'extended-tests/get-total-unchecked-tests',
        {
          params: { studentId },
        }
      ),
    total_tests_to_check: (teacherId: number) =>
      axios.get<TTotalUncheckedTestsEntity>(
        '/extended-tests/total-tests-to-check',
        {
          params: { teacherId },
        }
      ),
    VoiceComments: {
      sendAudio: (userId, testId, audio) => {
        const formData = new FormData();
        formData.append('audio_file', audio, 'comment.mp3');
        return axios.post(
          `/extended-tests/vcomment?user_id=${userId}&test_id=${testId}`,
          formData
        );
      },
      getAudioUrl: (id) => `${BASE_URL}/extended-tests/vcomment/${id}`,
      getAudio: (id) =>
        axios.get<TAudioUrlEntity>(`/extended-tests/vcomment/${id}/audio`),
    },
    Taking: {
      get: (id, asStudent) =>
        axios.get<TStudentWork>(`/extended-tests/${id}/taking/get`, {
          params: { asStudent: asStudent || undefined },
        }),
      save: (id, data) => axios.post(`/extended-tests/${id}/taking/save`, data),
      finish: (id, data) =>
        axios.post(`/extended-tests/${id}/taking/finish`, data),
    },
    Works: {
      getAll: (lessonId) =>
        axios.get<{ works: TWorksGetAll[] }>('/extended-tests/get-works', {
          params: { lessonId },
        }),
      grade: (testId, userId, data) =>
        axios.post('/extended-tests/grade', data, {
          params: { testId, userId },
        }),
      reset: (testId, userId) =>
        axios.post('/extended-tests/reset', null, {
          params: { testId, userId },
        }),
    },
    Templates: {
      get: (id) => axios.get<TTemplate>(`/extended-tests/templates/${id}/get`),

      all: () =>
        axios.get<{ templates: TTemplate[] }>('/extended-tests/templates/all'),

      create: (data) => axios.post('/extended-tests/templates/create', data),

      update: (id, data) =>
        axios.post(`/extended-tests/templates/${id}/update`, data),

      delete: (id) => axios.post(`/extended-tests/templates/${id}/delete`),
    },
    Images: {
      upload: (data, extension) =>
        axios.post('/extended-tests/images/upload', data, {
          params: { extension },
        }),
      getUrl: (id) => `${BASE_URL}/extended-tests/images/${id}`,
      delete: (id) => axios.post(`/extended-tests/images/${id}/delete`),
    },
  },
};
