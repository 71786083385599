import 'pages/main/dialog/changePasswordDialog.scss';

import hide from 'assets/images/hide.svg';
import show from 'assets/images/show.svg';
import React, { useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';

type TchangePasswordDialog = {
  resolve?: (value: string | null) => void;
};
const ChangePasswordDialog = React.memo(
  ({ resolve = () => {} }: TchangePasswordDialog) => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isPasswordVisible, setPasswordVisibility] = useState(false);
    const [isPasswordConfirmationVisible, setPasswordConfirmationdVisibility] =
      useState(false);

    const onOk = () => {
      const result = password.trim();
      resolve(result === '' ? null : result);
    };

    const modalData = {
      newPasswordFieldName: 'Новый пароль',
      newPasswordFieldPlaceholder: 'Введите новый пароль',
      passwordConfirmationFieldName: 'Подтверждение пароля',
      passwordConfirmationFieldPlaceholder: 'Введите подтверждение пароля',
      title: 'Сменить пароль',
    };

    return (
      <div>
        <Modal.Header>
          <Modal.Title>{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <Form>
            <Form.Group>
              <Form.Label>{modalData.newPasswordFieldName}</Form.Label>

              <InputGroup hasValidation>
                <Form.Control
                  type={isPasswordVisible ? 'text' : 'password'}
                  size="sm"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  isInvalid={
                    password.trim() === '' || password.trim().length < 6
                  }
                  placeholder={modalData.newPasswordFieldPlaceholder}
                />
                <InputGroup.Text className="bg-light">
                  <img
                    alt="img"
                    title={
                      isPasswordVisible ? 'Скрыть пароль' : 'Показать пароль'
                    }
                    src={isPasswordVisible ? hide : show}
                    onClick={() => setPasswordVisibility(!isPasswordVisible)}
                  />
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Пароль не может быть пустым или содержать менее 6 символов.
                </Form.Control.Feedback>
              </InputGroup>

              <Form.Label>{modalData.passwordConfirmationFieldName}</Form.Label>

              <InputGroup hasValidation>
                <Form.Control
                  type={isPasswordConfirmationVisible ? 'text' : 'password'}
                  size="sm"
                  value={passwordConfirmation}
                  required
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  isInvalid={
                    passwordConfirmation.trim() === '' ||
                    password.trim() !== passwordConfirmation.trim()
                  }
                  placeholder={modalData.passwordConfirmationFieldPlaceholder}
                />

                <InputGroup.Text className="bg-light">
                  <img
                    alt="img"
                    title={
                      isPasswordConfirmationVisible
                        ? 'Скрыть пароль'
                        : 'Показать пароль'
                    }
                    src={isPasswordConfirmationVisible ? hide : show}
                    onClick={() =>
                      setPasswordConfirmationdVisibility(
                        !isPasswordConfirmationVisible
                      )
                    }
                  />
                </InputGroup.Text>

                <Form.Control.Feedback type="invalid">
                  Пароли должны совпадать.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => resolve(null)}>Отмена</Button>
          {password.trim() === passwordConfirmation.trim() &&
          password.trim().length > 5 ? (
            <Button onClick={onOk}>ОК</Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </div>
    );
  }
);

export { ChangePasswordDialog };
